.emoji-mart-emoji {
	top: 6px;
}

.echarts-tooltip {
  overflow-y: auto;
  word-break: break-word;
  &::-webkit-scrollbar {
    display: none;
  }
}
